import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Moment from "moment";
import Report from "./report";

export default function Users() {
  const [search, setSearch] = useState("");
  const [date, setDate] = useState("");

  useEffect(() => {
    document.title = "Brix - Relatórios";
    const date = Moment().format("YYYY-MM-DD");
    setDate(date);
  }, []);

  return (
    <Routes>
      <Route path="*" element={ <Index /> } />
    </Routes>
  );

  function formatDate(fdate) {
    const rdate = Moment(fdate).format("YYYY-MM-DD");
    return rdate;
  }

  function Index() {
    return (
      <div className="container mt-3">
        <div className="row mb-3">
          <div className="col-12 col-md-6">
            <p className="title">
              Relatórios
            </p>
          </div>
          <div className="col-6 row d-flex justify-content-end">
            <div className="col-md-6">
              <input
                  type="text"
                  className="form-control"
                  autocomplete="off"
                  placeholder="Pesquisa..."
                  onChange={ (e) => { setSearch(e.target.value); }}
                  value={search}
                  autoFocus={search && true}
              />
            </div>
            <div className="col-md-6">
              <input
                type="date"
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                onChange={(e) => setDate(formatDate(e.target.value))}
                value={date}
                autoFocus={false}
                className="form-control"
                autocomplete="off"
                style={{
                  display: "inline-block",
                  width: "100%",
                  //borderRadius: 0,
                  //paddingTop: 5,
                  //paddingBottom: 5,
                }}
              />
            </div>
          </div>
        </div>
        <Routes>
          <Route index element={ <Report date={ date } searchTerm={ search } /> }/>
        </Routes>
      </div>
    );
  }
}