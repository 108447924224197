import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2"

import api from "../api/axios";
import Logo from "../assets/images/logo.png";

export default function ForgotPassword() {
  const [ email, setEmail ] = useState("");
  const history = useNavigate();

  function RequestReset(){
    api.post("/v1/define", { "email": email }).then(data => {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: data.data.message,
      }).then(() => {
        history("/");      
      })
    }).catch(err => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: err.response.data.message,
      });
    })
  }

  return (
    <div className="container mt-5 d-flex justify-content-center flex-wrap">
      <div className="d-flex justify-content-center mb-5 mt-5 fill">
        <img src={Logo} alt="logo" />
      </div>

      <div className="white-container col-md-6">
        <p id="forgot_my_password" className="container-title">Esqueci a Senha</p>

        <div className="form-floating mb-3">
          <input
            type="email"
            className="form-control"
            id="floatingInput"
            value={email}
            onChange={(e) => setEmail(e.target.value) }
            placeholder="name@example.com"
          />
          <label id="add_an_email" htmlFor="floatingInput">Insira seu e-mail</label>
        </div>

        <div className="d-grid gap-2 col-12 mx-auto mt-4">
          <button id="ask_reset_link" onClick={RequestReset} className="btn btn-primary">
            Enviar
          </button>
        </div>
      </div>
    </div>
  );
}