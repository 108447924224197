import { HashRouter, Route, Routes, Navigate } from "react-router-dom";
import Header from "./components/Header";
import LeftMenu from "./components/LeftMenu";

import Login from "./pages/login";
import Dashboard from "./pages/dashboard";
import ForgotPassword from "./pages/forgot_password";
import ChangePassword from "./pages/change_pwd";
import Define from "./pages/define";
import Create from "./pages/create";
import List from "./pages/list/index";
import Report from "./pages/report/index";
import User from "./pages/user/index";
import Pix from "./pages/pix/index";

export default function Routess() {
    return (
        <HashRouter>
            <Header />
            <Routes>
                <Route path="/" end element={ <Login /> } />
                <Route path="forgot_password" end element={ <ForgotPassword /> } />
                <Route path="define/:id" end element={ <Define /> } />
                <Route path="dashboard/*" element={ <Dash /> } />
                <Route path="*" element={ <Navigate replace to="/" /> } />
            </Routes>
        </HashRouter>
    );
}

function Dash() {
    return (
        <div className="container-fluid">
            <div className="row main ">
                <LeftMenu />
                <div className="col-md-10">
                    <Routes>
                        <Route index element={ <Dashboard /> } />
                        <Route path="change-pwd" element={ <ChangePassword /> } />
                        <Route path="create" element={ <Create /> } />
                        <Route path="list/*" element={ <List /> } />
                        <Route path="report/*" element={ <Report /> } />
                        <Route path="user/*" end element={ <User /> } />
                        <Route path="pix/*" end element={ <Pix /> } />
                    </Routes>
                </div>
            </div>
        </div>
    );
  }