import React, { useEffect, useState } from "react";
import api, { GetToken } from "../api/axios";
import { Navigate, useNavigate } from "react-router-dom";
//import jwt_decode from "jwt-decode";
import Loading from "../components/Loading";
import { useForm } from "react-hook-form";
import jwt_decode from "jwt-decode";

import Swal from "sweetalert2";
import InputMask from "react-number-format";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import Accordion from 'react-bootstrap/Accordion';
import { faCircleDollarToSlot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Create() {

  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const [mask, setMask] = useState();
  const [cpfCnpj, setCpfCnpj] = useState();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const token = GetToken();
    if (token === "null") {
      return <Navigate to="/" />;
    }

    if (!token) {
      return <Navigate to="/" />;
    }

    var userInfo = {};
    try {
      userInfo = jwt_decode(token);
      //setRole(userInfo.role);
    } catch (error) {
      console.log('👾 invalid token format', error);
      return true;
    }

    if (userInfo.chgpss === 1) {
      return null;
    }

  }, []);

  async function onSubmit(data) {
    setLoading(true);
    await api.post("/v1/charge/cob",
      {
        value: data.valor,
        order: data.order,
        ...mask && { persontype: mask },
        ...cpfCnpj && { personnumber: cpfCnpj },
        ...data.name && {personname: data.name }
      },
      { headers: { "x-access-token": GetToken() } }
    )
      .then((getcob) => {
        if (getcob.data.res) {
          const cod = getcob.data.cobdata.txid
          history("/dashboard/pix/" + cod);
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: getcob.data.message
          });
        }
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: JSON.stringify(err),
        });
      }).finally(() => setLoading(false));
  }

  function handleCpfCnpj(event, type) {
    setCpfCnpj(event.target.value);
    if (event.target.value.length === 0) {
      setMask("");
    } else {
      setMask(type);
    }
  }

  //CORES
  //#0000BF #3F7F00 #82FF05 #FBE731 #F60404"
  return (
    <div className="container mt-3">
      <Loading visible={loading} />
      <div className='row mb-3'>
        <div className='col-12 col-md-12'>
            <p id="create-pix" className="title">Gerar PIX</p>
        </div>
        <div className="row">
          <div className="col-md-6 col-12">
            <form onSubmit={handleSubmit(onSubmit)} className="form-content">
              <div className="row">
                <div className="col-md-12 mt-3">
                  Valor
                  <InputMask
                    {...register("valor", { required: true, min: 0.01 })}
                    prefix="R$ "
                    decimalSeparator=","
                    decimalScale="2"
                    fixedDecimalScale={true}
                    className="form-control"
                    allowNegative={false}
                    displayType="input"
                    onValueChange={(e) => setValue("valor", e.value) }
                    autoComplete="off"
                    thousandSeparator="."
                    inputMode='decimal' 
                  />
                  {errors.valor && (
                    <span className="required">
                      Requerido
                    </span>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  Descrição
                  <input
                    {...register("order", { required: false })}
                    className="form-control"
                    autoComplete="off"
                    maxLength={64}
                  />
                </div>
                <div className="col-md-12 mt-4">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item>
                    <Accordion.Header className="bg-white">Informações Adicionais</Accordion.Header>
                      <Accordion.Body>
                        <div className="col-md-12">
                          CPF / CNPJ
                          <CpfCnpj
                            {...register("person", { required: false })}
                            className="form-control"
                            autoComplete="off"
                            type="tel"
                            value={cpfCnpj}
                            onChange={(event, type) => { handleCpfCnpj(event, type) }}
                          />
                        </div>
                        <div className="col-md-12">
                          Nome
                          <input
                            {...register("name", { required: false })}
                            className="form-control"
                            autoComplete="off"
                            maxLength={200}
                          />
                        </div>
                      </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                </div>
              </div>

              <div className="d-flex flex-row-reverse bd-highlight">
                <button type="submit"
                  className="btn btn-orange btn-confirm float-end"
                >
                  <FontAwesomeIcon icon={ faCircleDollarToSlot } size="lg" className="btn-icon mr-4" />
                  Gerar PIX
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}