import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams, Outlet, Navigate } from "react-router-dom";
import api, { GetToken } from "../../api/axios";

import Swal from "sweetalert2";
import InputMask from "react-input-mask";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import jwt_decode from "jwt-decode";

export default function AddEdit() {
  const history = useNavigate();
  let { id } = useParams();
  const [phone, setPhone] = useState("");
  //const [country, setCountry] = useState([]);
  const [phoneMask, setPhoneMask] = useState("");
  const [image, setImage] = useState("");

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  function getNotify(app, sms, email) {
    const notify =
      "[" +
      app +
      needsComma("", app, sms) +
      sms +
      needsComma(app, sms, email) +
      email +
      "]";

    return notify === "[]" ? null : notify;
  }

  function needsComma(prev, value, next) {
    if (prev === "" && value === "" && next === "") return "";
    if (value === "" && prev !== "" && next !== "") return ",";
    if (value !== "" && next === "") return "";
    if (value === "") return "";
    return ",";
  }

  function onSubmit(data) {
    let phoneReplace = '';

    if (phone !== undefined && phone != null) {
      phoneReplace = phone
        .replace("+", "")
        .replace("(", "")
        .replace(")", "")
        .replace("-", "")
        .replace(" ", "");
    }

    if (isNaN(phoneReplace)) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Telefone Inválido",
      });
      return;
    }

    const appNot = data.notificationApp ? "0" : "";
    const smsNot = data.notificationSMS ? "1" : "";
    const emailNot = data.notificationEmail ? "2" : "";
    const notify = getNotify(appNot, smsNot, emailNot);

    if (id) {
      //edit
      api
        .put("/v1/user/update/" + id,
          {
            fname: data.fName,
            lname: data.lName,
            email: data.email,
            phone: phoneReplace,
            status: data.status,
            role: data.role,
            notify: notify,
            countryid: data.countryid,
          },
          { headers: { "x-access-token": GetToken() } }
        )
        .then(() => {
          //Swal.fire({
          //  icon: "success",
          //  title: "Success",
          //}).then(() => {
          history("/dashboard/user");
          //});
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data.message,
          });
        });
    } else {
      //create
      api
        .post("/v1/user/create",
          {
            fname: data.fName,
            lname: data.lName,
            email: data.email,
            phone: phoneReplace,
            status: data.status,
            notify: notify,
            role: data.role,
            countryid: 55,
          },
          { headers: { "x-access-token": GetToken() } }
        )
        .then(() => {
          //Swal.fire({
          //  icon: "success",
          //  title: "Success",
          // }).then(() => {
          history("/dashboard/user");
          //});
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err,
          });
        });
    }
  }

  useEffect(() => {

    const token = GetToken();
    if (token === "null") {
      return <Navigate to="/" />;
    }

    if (!token) {
      return <Navigate to="/" />;
    }

    var userInfo = {};
    try {
      userInfo = jwt_decode(token);
      //setRole(userInfo.role);
    } catch (error) {
      console.log('👾 invalid token format', error);
      return true;
    }

    if (userInfo.role !== 0) {
      history("/dashboard");
      return true;
    }

    if (userInfo.chgpss === 1) {
      return null;
    }

    if (id) {
      api
        .get("v1/user/getbyid/" + id, {
          headers: { "x-access-token": GetToken() },
        })
        .then((data) => {
          const value = data.data.message[0];
          setValue("fName", value.fname);
          setValue("lName", value.lname);
          setImage(value.image);
          setPhone(value.phone);
          setValue("email", value.email);
          setValue("status", value.status);
          setValue("role", value.role);
          setValue("notificationApp", value.notify.includes("0"));
          setValue("notificationSMS", value.notify.includes("1"));
          setValue("notificationEmail", value.notify.includes("2"));
          //setValue("countryid", value.countryid);
        })
        .catch((err) => {
          console.log(err.response);
        });
    }

      setPhoneMask("(99) 99999-9999");

  }, [id, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form-content">
      {image ? (
        <div className="row">
          <div className="col-md-2 mb-3">
            <img src={image} class="img-thumbnail" alt="" />
          </div>
        </div>
      ) : null}
      <div className="row">
        <div className="col-md-6">
          Nome
          <input
            {...register("fName", { required: true, min: 1 })}
            className="form-control"
          />
          {errors.fName && (
            <span className="required">
              Requerido
            </span>
          )}
        </div>

        <div className="col-md-6">
          Sobrenome
          <input
            {...register("lName", { required: true, min: 1 })}
            className="form-control"
          />
          {errors.lName && (
            <span className="required">
              Requerido
            </span>
          )}
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-md-6">
          Telefone
          <InputMask
            mask={phoneMask}
            onChange={(e) => setPhone(e.target.value)}
            value={phone}
            className="form-control"
          />
        </div>

        <div className="col-md-6">
          E-mail
          <input
            {...register("email", {
              required: true,
              pattern:
                /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
            })}
            className="form-control"
          />
          {errors.email && (
            <span className="required">
              Requerido
            </span>
          )}
        </div>
      </div>
      
      <div className="row">
        {/*<div className="col-md-3 mt-3">
          País
          <select {...register("countryid", { required: true })} className="form-select">
              {(placeholder) => {
                return <option value="">{placeholder}</option>;
              }}
            {country.map((data) => (
              <option value={data.id}>{data.name}</option>
            ))}
          </select>
            </div>*/}

        <div className="col-md-3 mt-3">
          Tipo
          <select {...register("role", { required: true })} className="form-select">
            <option value="">Selecione</option>
            <option value="0">Admin</option>
            <option value="1">Operador</option>
          </select>
          {errors.role && (
            <span className="required">
              Requerido
            </span>
          )}
        </div>

        <div className="col-md-3 mt-3">
          Status
          <select {...register("status", { required: true })} className="form-select">  
              <option value="">Selecione</option>
              <option value="1">Ativo</option>
              <option value="0">Inativo</option>
          </select>
          {errors.status && (
            <span className="required">
              Requerido
            </span>
          )}
        </div>

        <div className="col-md-6 mt-3">
          Notificações
          <div className="row">
            <div className="col-md-4">
              <div className="form-check">
                <input
                  {...register("notificationApp")}
                  className="form-check-input"
                  type="checkbox"
                  id="notificationAppInput"
                />
                <label
                  className="form-check-label"
                  htmlFor="notificationAppInput"
                >
                  APP
                </label>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-check">
                <input
                  {...register("notificationSMS")}
                  className="form-check-input"
                  type="checkbox"
                  id="notificationSmsInput"
                />
                <label
                  className="form-check-label"
                  htmlFor="notificationSmsInput"
                >
                  SMS
                </label>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-check">
                <input
                  {...register("notificationEmail")}
                  className="form-check-input"
                  type="checkbox"
                  id="notificationEmailInput"
                />
                <label
                  className="form-check-label"
                  htmlFor="notificationEmailInput"
                >
                  E-MAIL
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex flex-row-reverse bd-highlight">
        <button type="submit"
          className="btn btn-orange btn-confirm float-end"
        >
          <FontAwesomeIcon icon={faSave} size="lg" className="btn-icon mr-4" />
          {id ? "Salvar" : "Salvar" }
        </button>
        <button
          className="btn btn-secondary btn-confirm"
          onClick={() => history("/dashboard/user") }
        >
          Cancelar
        </button>
      </div>
      <Outlet />
    </form>
  );
}
