import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Moment from "moment";
import ListPix from "./list";

export default function List() {
  //var pathName = useLocation().pathname;
  const [date, setDate] = useState("");

  useEffect(() => {
    document.title = "Brix - Listar PIX";
    const date = Moment().format("YYYY-MM-DD");
    setDate(date);
  }, []);

  return (
    <Routes>
      <Route path="*" element={ <Index /> } />
    </Routes>
  );

  function formatDate(fdate) {
    const rdate = Moment(fdate).format("YYYY-MM-DD");
    return rdate;
  }

  function Index() {
    return (
      <div className="container mt-3">
        <div className="row mb-3">
          <div className="col-12 col-md-6">
            <p className="title">
              Listar PIX
            </p>
          </div>
          <div className="col-md-6">
            <div className="col-6 col-md-8">
              <input
                type="date"
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                onChange={(e) => setDate(formatDate(e.target.value))}
                value={date}
                autoFocus={false}
                className="form-control col"
                style={{
                  display: "inline-block",
                  width: "100%",
                  //borderRadius: 0,
                  //paddingTop: 5,
                  //paddingBottom: 5,
                }}
              />
            </div>
          </div>
        </div>
        <Routes>
          <Route index element={ <ListPix date={ date } /> }/>
        </Routes>
      </div>
    );
  }
}