import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import Pix from "./pix";

export default function List() {
  //var pathName = useLocation().pathname;

  useEffect(() => {
    document.title = "Brix - Listar PIX";
  }, []);

  return (
    <Routes>
      <Route path="*" element={ <Index /> } />
    </Routes>
  );


  function Index() {
    return (
      <div className="container mt-3">
        <div className="row mb-3">
          <div className="col-12 col-md-6">
            <p className="title">
              Detalhes do PIX
            </p>
          </div>
        </div>
        <Routes>
          <Route path=":id" element={ <Pix /> }/>
        </Routes>
      </div>
    );
  }
}