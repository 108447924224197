import React, { useEffect, useState } from "react";
import api, { GetToken } from "../api/axios";
import { Navigate, useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import Loading from "../components/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBillAlt, faMoneyBillTransfer } from "@fortawesome/free-solid-svg-icons";

export default function Dashboard() {

  const history = useNavigate();
  const [role, setRole] = useState();
  const [balance, setBalance] = useState("0.00");
  const [sum, setSum] = useState("0.00");
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);


  useEffect(() => {

    //setLoading(true);

    const token = GetToken();
    if (token === "null") {
      return <Navigate to="/" />;
    }

    if (!token) {
      return <Navigate to="/" />;
    }

    var userInfo = {};
    try {
      userInfo = jwt_decode(token);
      setRole(userInfo.role);
    } catch (error) {
      console.log('👾 invalid token format', error);
      return true;
    }

    if (userInfo.chgpss === 1) {
      return null;
    }

  }, [history]);
    
  useEffect(() => {
    if (role === 0) {
      GetBalance();
    }
    GetSum();
  }, [role]);

  function GetBalance() {
    setLoading1(true);
    
    api.get('/v1/dashboard/getbalance', 
      { headers: { "x-access-token": GetToken() }
    })
      .then(getbalance => {
        if (!getbalance.data.res) {
          } else {
          setBalance(getbalance.data.message);
        }
      }).catch(_ => {
        console.log(_);
      })
      .finally(() => {
        setLoading1(false);
      });
  }

  function GetSum() {
    setLoading2(true);
    
    api.get('/v1/charge/getsum', 
      { headers: { "x-access-token": GetToken() }
    })
      .then(getsum => {
        if (!getsum.data.res) {
          } else {
          setSum(getsum.data.message);
        }
      }).catch(_ => {
        console.log(_);
      })
      .finally(() => {
        setLoading2(false);
      });
  }

  //CORES
  //#0000BF #3F7F00 #82FF05 #FBE731 #F60404"

  //com R$
  //var f = atual.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
  //sem R$
  //var f2 = atual.toLocaleString('pt-br', {minimumFractionDigits: 2});

  return (
    <div className="container mt-3">
      <Loading visible={loading1 || loading2} />
      <h3 className="title mb-3">
        Dashboard
      </h3>
      {role === 0 &&
      <div className="row mb-3">
        <div className="col-sm-3">
          <div className="table-list p-3">
            <h5 style={{ color: "#A6A6A6", fontSize: "14px" }}>Saldo em conta</h5>
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={faMoneyBillAlt}
                color={"#85BB65"}
                size="2x"
              />
              {!loading1 && <h5 style={{ color: "black", fontSize: "18px", margin: "2px 0 0 15px", fontWeight: 700 }}>{parseFloat(balance.saldo).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</h5>}
            </div>
          </div>
        </div>
      </div>
      }
      <div className="row mb-3">
        <div className="col-sm-3">
          <div className="table-list p-3">
            <h5 style={{ color: "#A6A6A6", fontSize: "14px" }}>Arrecadação do dia</h5>
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={faMoneyBillTransfer}
                color={"#85BB65"}
                size="2x"
              />
              {!loading2 && <h5 style={{ color: "black", fontSize: "18px", margin: "2px 0 0 15px", fontWeight: 700 }}>{parseFloat(sum.amount).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</h5>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}