import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faEye } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import api from "../api/axios";
import Logo from "../assets/images/logo.png";
import Loading from "../components/Loading";

const eye = <FontAwesomeIcon icon={faEye} />;

export default function Define() {
  const history = useNavigate();

  let { id } = useParams();

  const [pass, setPass] = useState("");
  const [confPass, setConfPass] = useState("");
  const [loading, setLoading] = useState(false);
  const [isRevealPass, setIsRevealPass] = useState(false);
  const [isRevealConfPass, setIsRevealConfPass] = useState(false);

  function HandleSend() {
    setLoading(true);

    api
      .post("/v1/define/" + id, { pass: pass })
      .then((data) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: data.data.message,
        }).then(() => {
          history("/");
        });
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response.data.message,
        });
      }).finally(_ => {
        setLoading(false);
      });
  }

  return (
    <div className="container mt-5 d-flex justify-content-center flex-wrap">
      <Loading visible={loading} />

      <div className="d-flex justify-content-center mb-5 mt-5 fill">
        <img src={Logo} alt="logo" />
      </div>

      <div className="white-container col-md-6">
        <p className="container-title">Criar Senha</p>

        <div className="form-floating mb-3 pwd-container">
          <input
            id="floatingPasswordInput"
            type={isRevealPass ? "text" : "password"}
            value={pass}
            className="form-control"
            onChange={e => setPass(e.target.value)}
            placeholder="Password"
            />
            <i onClick={() => setIsRevealPass(prevState => !prevState)}>{eye}</i>
          <label htmlFor="floatingPasswordInput">Nova senha</label>
        </div>

        <div className="form-floating mb-3 pwd-container">
        <input
            id="floatingConfPasswordInput"
            type={isRevealConfPass ? "text" : "password"}
            value={confPass}
            className="form-control"
            onChange={e => setConfPass(e.target.value)}
            placeholder="Password"
            />
            <i onClick={() => setIsRevealConfPass(prevState => !prevState)}>{eye}</i>
          <label htmlFor="floatingConfPasswordInput">
            Confirme a nova senha
          </label>
        </div>

        <div className="d-grid gap-2 col-12 mx-auto mt-4">
          <button onClick={HandleSend} className="btn btn-primary">
            <FontAwesomeIcon id="continue" icon={faSave} size="lg" className="btn-icon mr-4" />
            Salvar
          </button>
        </div>
      </div>
    </div>
  );
}