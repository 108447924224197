import axios from "axios";

const api = axios.create({
  baseURL: "https://api.brix.app.br",
  headers: {
    'Access-Control-Allow-Origin': '*',
    'x-access-token': GetToken(),
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0'
  }
});

function SaveToken(token){
  localStorage.setItem('token', token);
}

function GetToken(){  
  if (!localStorage.getItem('token')) {
    return null;
  }
  return localStorage.getItem('token');
}

export { SaveToken, GetToken };
export default api;