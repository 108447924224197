import React from 'react';
import Routess from "./routes";

function App() {
  return (
    <Routess />
  );
}

export default App;
