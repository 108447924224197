import React, { useEffect, useState } from "react";
import { useNavigate, Navigate } from "react-router-dom";

import {
  faAngleLeft,
  faAngleRight,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import jwt_decode from "jwt-decode";

import api, { GetToken } from "../../api/axios";
import Loading from "../../components/Loading";

export default function List(props) {
  const history = useNavigate();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [perPage] = useState(50);
  const [pageCount, setPageCount] = useState(0);
  const [update, setUpdate] = useState(false);
  const [, setSelected] = useState(0);
  
  useEffect(() => {

    const token = GetToken();
    if (token === "null") {
      return <Navigate to="/" />;
    }

    if (!token) {
      return <Navigate to="/" />;
    }

    var userInfo = {};
    try {
      userInfo = jwt_decode(token);
      //setRole(userInfo.role);
    } catch (error) {
      console.log('👾 invalid token format', error);
      return true;
    }

    if (userInfo.role !== 0) {
      history("/dashboard");
      return true;
    }

    if (userInfo.chgpss === 1) {
      return null;
    }

    UpdateList();
    
  }, []);

  function deleteUserById(id) {
    let newRosters = users;

    newRosters = newRosters.filter(users => users.id !== id);

    setUsers(newRosters);
    setUpdate(!update);
  }

  function UpdateList() {
    setLoading(true);
    api.get("/v1/user/getlist", { headers: { "x-access-token": GetToken() } })
      .then((data) => {
        const SearchTerm = props.searchTerm.toLowerCase();
        const filter = data.data.message.filter((x) =>
          x.fname.toLowerCase().includes(SearchTerm) ||
          x.lname.toLowerCase().includes(SearchTerm)
        );
        const slice = filter.slice(offset, offset + perPage);

        setUsers(slice);
        setPageCount(Math.ceil(filter.length / perPage));
      })
      .catch((err) => {
        console.log(err.response);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function DeleteUser(id) {
    Swal.fire({
      title: "Exclusão",
      text: "Você tem certeza que deseja excluir este usuário?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#565e64",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        api
          .delete("/v1/user/delete/" + id, {
            headers: { "x-access-token": GetToken() },
          })
          .then((data) => {
            Swal.fire({
              icon: "success",
              title: "Sucesso",
              text: "Usuário excluído com sucesso!",
            }).then(() => {
              //UpdateList();
              deleteUserById(id);
              setLoading(false);
            });
          })
          .catch((err) => {
            setLoading(false);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: err.response.data.message,
            });
          });
      }
    });
  }

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    let offset = Math.ceil(selectedPage * perPage);
    setOffset(offset);
    setSelected(selectedPage);
  };

  function maskPhone(phone) {
    var x = phone.replace(/\D/g, '').match(/(\d{2})(\d{5})(\d{4})/);
    return '(' + x[1] + ') ' + x[2] + '-' + x[3];
  };


  return (
    <div>
      <Loading visible={loading} />

      <div className="table-container">
        <table className="table table-list btn100">
          <thead>
            <tr>
              <th scope="col">
                Nome
              </th>
              <th scope="col">E-mail</th>
              <th scope="col">
                Telefone
              </th>
              <th scope="col"> 
                Tipo
              </th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => {
              return (
                <tr key={user.id}>
                  <td>{user.fname} {user.lname}</td>
                  <td>{user.email}</td>
                  <td>{ (user.phone?maskPhone(user.phone):'') }</td>
                  <td>
                    {user.role === 0 ? "Admin" : "Operador"}
                  </td>
                  <td>
                    <div
                      className="table-status"
                      style={{
                        backgroundColor: user.status === 0
                          ? "red"
                          : "green",
                      }}>
                      {

                        user.status === 0 ? "Inativo" : "Ativo"
                      }
                    </div>
                  </td>
                  <td className='icon-cell'>
                    <FontAwesomeIcon
                      icon={faEdit}
                      onClick={() => history("edit/" + user.id)}
                    />
                  </td>
                  <td className='icon-cell'>
                    <FontAwesomeIcon
                      icon={faTrash}
                      onClick={() => DeleteUser(user.id)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div>
        <ReactPaginate
          previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
          nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={ (e) => handlePageClick() }
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      </div>
    </div>
  );
}
